import { defineStore } from 'pinia'

export const useSubStore = defineStore({
  
  id: 'subscribe-store',
  persist: true,

  state: () => {
    return {
      country: null,
      email: null,
      fname: null,
      lname: null,
      subscriptionType: null,
      referral: null
    }
  },

  actions: {

    clearSubStore() {
      this.country = null
      this.email = null
      this.fname = null
      this.lname = null
      this.subscriptionType = null
      this.referral = null
    }

  }
  
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSubStore, import.meta.hot))
}