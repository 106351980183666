export const useUser = () => {
  
  const user = useSupabaseUser()
  const supabase = useSupabaseClient()
  const userStore = useUserStore()
  const subStore = useSubStore()
  const messageStore = useMessageStore()

  const fetchProfile = async () => {
    if (!user.value?.id) return
    
    try {
      const { data: userProfile, error: userProfileError } = await supabase
        .from('profiles')
        .select('*, profiles_notifications(notifications_id)')
        .eq('id', user.value.id)
        .maybeSingle()

      if (!userProfileError && userProfile) {
        userStore.setProfile(userProfile)
      }
    } catch(error) {
      console.error('Profile fetch error:', error)
    }
  }

  const fetchNotifications = async () => {
    if (!user.value) return
    
    try {
      const { data: userNotifications, error: userNotificationsError } = await supabase
        .from('notifications')
        .select('id')

      const systemNotifications = Array.isArray(userNotifications) ? userNotifications.length : 0
      const userRead = userStore.profile?.profiles_notifications ? userStore.profile.profiles_notifications.length : 0
      
      userStore.notifications = systemNotifications - userRead
    } catch(error) {
      console.error('Notification fetch error:', error)
    }
  }

  const signOut = async () => {
    if (!user.value) return
    navigateTo('/', { replace: true })
    subStore.clearSubStore()
    userStore.signOut()
    try {
      const { error } = await supabase.auth.signOut()
      if (error) {
        messageStore.message(`Sign out error: ${error}`, 10, 'error')
      }
    } catch(error) {
      console.error('Sign out error:', error)
    }
  }

  return {
    fetchProfile,
    fetchNotifications,
    signOut
  }
}